import React, {useEffect, useState} from 'react';
import VariableSelectWrite from "../../../GlobalComponent/VariableSelectWrite";
import VariableNumberInput from "../../../GlobalComponent/VariableNumberInput";

const OpenUrl = (props) => {
    const [dataState, setDataState] = useState(props?.nodeData?.data?.options ?? {
        url: "",
        timeout: 30000,
        waitUntil: "domcontentloaded",
        referer: ""
    });

    const handleChangeUrl = (type, value) => {
        if (typeof value === 'string') {
            setDataState({...dataState, [type]: value})
            props.handleSetDataBaseModal({ type: value })
        } else {
            setDataState({...dataState, [type]: value.target.value})
        }
    }

    useEffect(() => {
        props.handleSetDataBaseModal(dataState)
    }, [props, dataState])

    return (
        <>
            <VariableSelectWrite
                placeholder="Enter URL"
                label="URL"
                dataState={dataState.url}
                handleData={(e) => {
                    handleChangeUrl('url', e)
                }}
                handleSelect={(e) => {
                    const value = e.target.getAttribute('value')
                    handleChangeUrl('url', dataState.url + "${" + value + "}")
                }}
            />
            <VariableNumberInput
                label="Timeout waiting (milliseconds)"
                dataState={dataState.timeout}
                handleData={(e) => {
                    handleChangeUrl('timeout',e)
                }}
                isMillisecond={true}
            />
            <VariableSelectWrite
                placeholder="Referer header value"
                label="Referer header"
                dataState={dataState.referer}
                handleData={(e) => {
                    handleChangeUrl('referer', e)
                }}
                handleSelect={(e) => {
                    const value = e.target.getAttribute('value')
                    handleChangeUrl('referer', dataState.referer + "${" + value + "}")
                }}
            />
        </>
    );
}

export default OpenUrl;
