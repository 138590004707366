import VariableNumberInput from "../../../GlobalComponent/VariableNumberInput";
import VariableSelectWrite from "../../../GlobalComponent/VariableSelectWrite";
import React, { useState, useEffect } from "react";

const RunCommand = (props) => {
    const [dataState, setDataState] = useState(props?.nodeData?.data?.options??{
        command:'',
        dir:'',
        timeout: 0,
    })

    const handleSelector = (type, value) => {
        if (typeof value === 'string' || typeof value === 'number') {
            setDataState({...dataState, [type]: value});
        } else {
            setDataState({...dataState, [type]: value.target.value});
        }
    }

    useEffect(() => {
        props.handleSetDataBaseModal(dataState)
    }, [props, dataState])

    return (
        <div>
            <VariableSelectWrite
                placeholder={'Ex: file.txt or C:\\user\\text\\test.bat...'}
                label="Command line"
                dataState={dataState.command}
                handleData={(e) => {
                    setDataState((pre) => ({...pre, command:e.target.value}))
                }}
                handleSelect={(e) => {
                        const value = e.target.getAttribute('value')
                        const _valueInput = dataState.command+"${" + value + "}"
                        setDataState((pre) => ({...pre, command:_valueInput}))
                    }
                }
            />
            <VariableSelectWrite
                placeholder={'Enter dir. Ex: C:\\'}
                label="Dir (option)"
                dataState={dataState.dir}
                handleData={(e) => {
                    setDataState((pre) => ({...pre, dir:e.target.value}))
                }}
                handleSelect={(e) => {
                        const value = e.target.getAttribute('value')
                        const _valueInput = dataState.dir+"${" + value + "}"
                        setDataState((pre) => ({...pre, dir:_valueInput}))
                    }
                }
            />
            <div className="mt-3">
                <VariableNumberInput
                    label="Time Skip (milliseconds)"
                    dataState={dataState.timeout}
                    handleData={(e) => {
                        handleSelector('timeout',e)
                    }}
                    isMillisecond={true}
                />
            </div>
        </div>
    )
}

export default RunCommand