import React, {useState, useEffect, useRef, useCallback, forwardRef, useImperativeHandle, useContext} from 'react';
import ReactFlow, {
    ReactFlowProvider,
    addEdge,
    useNodesState,
    useEdgesState,
    Controls,
    Background,
    MiniMap,
    getConnectedEdges,
    useReactFlow,
} from 'reactflow';
import 'reactflow/dist/style.css';
import './AutomationFlow.css';
import Start from '../../components/NodeFlow/Start/Start';
import Normal from '../../components/NodeFlow/Normal/Normal';
import Loop from "../../components/NodeFlow/Loop/Loop";
import Stop from "../../components/NodeFlow/Stop/Stop";
import Comment from "../../components/NodeFlow/Comment/Comment";
import BaseModal from '../../components/Modal/BaseModel';
import {GetBodyModalComponent} from '../../components/Modal/BodyModal/GetBodyModalComponent';
import {v4 as uuid} from 'uuid';
import CustomEdge from '../../components/EdgeFlow/CustomEdge';
import {createScript, findScriptById, updateScript} from '../../request/Automation';
import {apiNotifications, systemNotifications} from '../../components/Notifications/Notifications';
import {ScriptContext} from "../context/ScriptContext";
import Variables from '../../components/NodeFlow/Variables/Variables';
import { DataFlowContext } from '../context/DataFlowContext';
import styled from 'styled-components';
import { Group, createStyles } from '@mantine/core';
import ActionStatus from '../Navbar/actionStatus';
import { isEqual } from 'lodash';
import { GetDocumentModalComponent } from '../../components/Modal/BodyModal/GetDocumentModalComponent';
import ContextMenu from './ContextMenu';
import { isEmpty, cloneDeep } from 'lodash';

const MiniMapStyled = styled(MiniMap)`
    background-color: ${(props) => props.theme.bg};

    .react-flow__minimap-mask {
        fill: ${(props) => props.theme.minimapMaskBg};
    }

    .react-flow__minimap-node {
        fill: ${(props) => props.theme.nodeBg};
        stroke: none;
    }
`;

const ControlsStyled = styled(Controls)`
    button {
        background-color: ${(props) => props.theme.controlsBg};
        color: ${(props) => props.theme.controlsColor};
        border-bottom: 1px solid ${(props) => props.theme.controlsBorder};

        &:hover {
        background-color: ${(props) => props.theme.controlsBgHover};
        }

        path {
        fill: currentColor;
        }
    }
`;

const nodeTypes = {
    nodeStart: Start,
    normalNode: Normal,
    nodeVariables: Variables,
    nodeLoop: Loop,
    nodeStop: Stop,
    nodeComment: Comment
};

// setInterval(() => {
//     console.log('window.api', window.api)
// }, 5000)

const edgeTypes = {
    customEdge: CustomEdge,
};

const useStyles = createStyles((theme) => ({
    action: {
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.white,
        // width: '440px',
        height: '100px',
        position: 'absolute',
        bottom: '0',
        left: '60%',
        transform: 'translate(-60%, 0)',
        padding: "30px 20px",
        height: 'fit-content',
    }
}))

const defaultViewport = {x: 70, y: 150, zoom: 1.2};

const AutomationFlow = (props, ref) => {

const variablesContext = useContext(DataFlowContext)
const { classes } = useStyles();
const initialNodes = [
    {
        id: uuid(),
        type: 'nodeStart',
        height: 63,
        width: 63,
        data: { label: 'Start', type: "nodeStart" },
        position: { x: 0, y: 0 },
        targetPosition: "right"
    },
        {
            id: uuid(),
            type: 'nodeVariables',
            width: 120,
            height: 40,
            data: {
                label: 'Variables',
                type: "nodeVariables",
                options: {
                    variables: variablesContext.nodeValue.variables
                }
            },
            position: {x: 100, y: 100},
            targetPosition: "right"
        }
    ];
    const reactFlowWrapper = useRef(null);
    const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    const [reactFlowInstance, setReactFlowInstance] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [nodeData, setNodeData] = useState('');
    const [initScript, setInitScript] = useState(null);
    const scriptContext = useContext(ScriptContext);
    const oldState = useRef({ nodes, edges, isSetDone: false })
    const [menu, setMenu] = useState(null);
    const refFlow = useRef(null);
    const { setViewport } = useReactFlow();
    const restore = useRef([])
    const storeFlow = useRef({ node: [], edge: [] })
    const onConnect = useCallback((params) => {
        // const sourceNode = reactFlowInstance.getNode(params.sourceHandle);
        let colorEdges = {
            stroke: "var(--output)"
        }
        if (params.sourceHandle.includes('error')) { 
            colorEdges.stroke = "var(--output-bottom)"
        }
        //Thêm một số attr vào edges
        // edges.map((element) => {
        //     if (element.target === params.source) {
        //         if (params.sourceHandle.includes("success")) element.success = params.target;
        //         if (params.sourceHandle.includes("error")) element.error = params.target;
        //     }
        //
        //     return element;
        // })
        //Tạo edges
        setEdges((eds) => addEdge({...params, type: 'customEdge', style: colorEdges, data: {setEdges}}, eds))

    }, [edges, setEdges]);

    const onDragOver = useCallback((event) => {
        event.preventDefault();
        event.dataTransfer.dropEffect = 'move';
    }, []);

    const onDrop = useCallback(
        (event) => {
            event.preventDefault();

            const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
            const type = event.dataTransfer.getData('application/reactflow');
            const data = JSON.parse(event.dataTransfer.getData('data'));
            console.log({reactFlowBounds});
            // check if the dropped element is valid
            if (typeof type === 'undefined' || !type) {
                return;
            }
            const position = reactFlowInstance.project({
                x: event.clientX - reactFlowBounds.left,
                y: event.clientY - reactFlowBounds.top,
            });

            const newNode = {
                id: uuid(),
                type: type,
                position,
                data: {...data, onRemoveNode: onRemoveNode, onUpdateNode: onUpdateNode}
            };
            setNodes((nds) => nds.concat(newNode));
        },
        [reactFlowInstance, setNodes]
    );

    const onRemoveNode = (nodeId) => {
        const edges = reactFlowInstance.getEdges()
        const edgesToRemove = getConnectedEdges([reactFlowInstance.getNode(nodeId)], edges)

        //xóa tất cả edge nối tới node
        if (edgesToRemove.length) {
            reactFlowInstance.setEdges((edges) => edges.filter((ed) => !edgesToRemove.find(edgeToRemove => edgeToRemove.id === ed.id)));
        }

        //xóa node
        reactFlowInstance.setNodes((nds) => nds.filter((node) => node.id !== nodeId))
    }

    const onUpdateNode = (nodeId) => {
        const node = reactFlowInstance.toObject().nodes.find(n => n.id === nodeId)
        const NODE_NOT_OPEN_MODAL = ["stopListenRequest", "stopCode", "stopLoop"]
        if (NODE_NOT_OPEN_MODAL.includes(node.data.type)) return;
        setNodeData(node);
        setShowModal(true)
    }

    const onClickNode = (e, node) => {
        const NODE_NOT_OPEN_MODAL = ["stopListenRequest", "stopCode", "stopLoop"]
        if (NODE_NOT_OPEN_MODAL.includes(node.data.type)) return;
        setShowModal(true)
        setNodeData(node);
    }

    const updateDataInNode = (data) => {
        setNodes((nds) =>
            nds.map((node) => {
                if (data && node.id === nodeData.id) {
                    node.data.options = {...data}
                }

                return node;
            })
        );
    }
    //Save flow from navbar (update, create)
    useImperativeHandle(ref, () => ({
        handleDbClick(newNode) { // dbclick new node
            const randomNumber = Math.floor(Math.random() * 100) + 50
            const { width, height } = reactFlowWrapper.current.getBoundingClientRect();
            const position = reactFlowInstance.project({
                x: width/2 + randomNumber,
                y: height/2 + randomNumber,
            });
            newNode.data.onRemoveNode = onRemoveNode
            newNode.data.onUpdateNode = onUpdateNode
            setNodes((nds) => nds.concat({...newNode, position: position}));
        },
        handleSave(name = "flow", saveAs = false) {
            if (reactFlowInstance) {
                const flow = reactFlowInstance.toObject();
                //gán lại variable
                flow.nodes[1].data.options = variablesContext.nodeValue;
                if (!flow.edges.length) {
                    systemNotifications({
                        type: "error",
                        message: "Please define start command of the scenario!"
                    });
                    return { type: "error" }
                }

                const body = {
                    name: name,
                    type: 'flow',
                    status: 'active',
                    script: JSON.stringify(flow)
                }
                console.log(JSON.stringify(flow))
                if (scriptContext.scriptId && saveAs === false) {
                    updateScript({body: body, id: scriptContext.scriptId})
                        .then((res) => {
                            apiNotifications(res)
                            window.parent.postMessage({ type: "script_saved" }, "*")
                            window.electronAPI.saveScript({ ...body, key: scriptContext.scriptId })
                        })
                        .catch((err) => {
                            apiNotifications(err)
                        })
                    return;
                }

                createScript({body: body})
                    .then((res) => {
                        apiNotifications(res)
                        scriptContext.setScriptId(res.data.content.id);
                        window.parent.postMessage({ type: "script_saved" }, "*")
                        window.electronAPI.saveScript({ ...body, key: res.data.content.id })
                    })
                    .catch((err) => {
                        apiNotifications(err)
                    })
            }
        },
        handleRunTest(uuid, name) {
            window.api.mb_ipcRenderer.sendMsg("automation_flow", {action: "run", browser_name: name , browser_uuid: uuid,scripts:JSON.stringify(reactFlowInstance.toObject())})
        },
        handleShowLog(){
            window.api.mb_ipcRenderer.sendMsg("automation_flow", {action: "show_log"})
        },
        handleRemoveNodes() {
            const nodes = reactFlowInstance.getNodes();
            const nodesDefault = ["nodeStart", "nodeVariables"]
            const nodesToRemove = nodes.filter((node) => node.selected && !nodesDefault.includes(node.type)).map(n => n.id)
            // xóa tất cả edge nối tới nodes
            reactFlowInstance.setEdges((edges) => edges.filter(edge => !nodesToRemove.includes(edge.target) && !nodesToRemove.includes(edge.source)));

            // xoá nodes
            reactFlowInstance.setNodes((nds) => nds.filter((node) => !nodesToRemove.includes(node.id)))
        },
        handleShowVariables() {
            setShowModal(true)
            const nodes = reactFlowInstance.getNodes();
            const nodeVariables = nodes.find(nds => nds.type === 'nodeVariables')
            setNodeData(nodeVariables)
        }
    }));
    useEffect(() => {
        if (props.updateId) {
            if (initScript === null) {
                findScriptById({id: props.updateId})
                    .then((res) => {
                        setInitScript(JSON.parse(res.data.content?.script));
                        scriptContext.setScriptId(res.data.content.key);
                        scriptContext.setScriptName(res.data.content.name);
                    })
                    .catch((err) => {
                        apiNotifications(err)
                    })
            } else {
                let scriptContent = initScript;
                let commentData = {};
                scriptContent.nodes.map((element) => {
                    element.data = Object.assign(element.data, {onRemoveNode: onRemoveNode, onUpdateNode: onUpdateNode});
                    if (element.type === "nodeComment") {
                        if (element.data.options.hasOwnProperty('id')) {
                            commentData[element.data.options.id] = element.data.options.value;
                        }
                    }
                });
                variablesContext.setComment(commentData);
                setNodes(scriptContent.nodes);
                variablesContext.setNodeValue(scriptContent.nodes[1].data.options);
                scriptContent.edges.map((element) => {
                    setEdges((eds) => addEdge({ ...element, type: 'customEdge', data: { setEdges } }, eds))
                });

                // SET OLD STATE START
                setEdges((eds) => {
                    oldState.current.edges = eds
                    return eds
                })
                oldState.current.nodes = scriptContent.nodes
                oldState.current.isSetDone = true
                // SET OLD STATE END
            }
        }

    }, [props.updateId, setNodes, setEdges, initScript]);



    // const ReactFlowStyled = styled(ReactFlow)`
    //     background-color: ${(props) => props.theme.bg};
    // `;

    const compareScriptAndSendToParent = () => {
        const isEqualNodes = isEqual(oldState.current.nodes, nodes)
        const isEqualEdges = isEqual(oldState.current.edges, edges)
        if (!isEqualNodes || !isEqualEdges) {
            window.parent.postMessage({ type: "script_change" }, "*")
        }
        oldState.current = { nodes, edges, isSetDone: true };
    }

    useEffect(() => {
        if (!props.updateId) {
            compareScriptAndSendToParent()
        } else if (props.updateId && oldState.current.isSetDone) {
            compareScriptAndSendToParent()
        }
    }, [nodes, edges, props.updateId])

    const onSelectionContextMenu = useCallback((event, node) => {
        event.preventDefault();
        const pane = refFlow.current.getBoundingClientRect();
        setMenu({
            top: event.clientY < pane.height - 100 && event.clientY,
            left: event.clientX - 360 < pane.width - 100 && event.clientX - 360,
            right: event.clientX - 360 >= pane.width - 100 && pane.width - (event.clientX - 360),
            bottom: event.clientY >= pane.height - 100 && pane.height - event.clientY,
          });
    },[setMenu])

    const onPaneContextMenu = useCallback((event) => {
        event.preventDefault();
        const pane = refFlow.current.getBoundingClientRect();
        setMenu({
            onPane: true,
            top: event.clientY < pane.height - 100 && event.clientY,
            left: event.clientX - 360 < pane.width - 100 && event.clientX - 360,
            right: event.clientX - 360 >= pane.width - 100 && pane.width - (event.clientX - 360),
            bottom: event.clientY >= pane.height - 100 && pane.height - event.clientY,
          });
    },[setMenu])

    const onNodeContextMenu = useCallback((event, node) => {
          // Prevent native context menu from showing
          event.preventDefault();
          const nodesDefault = ["nodeStart", "nodeVariables", "nodeComment"]
          if (nodesDefault.includes(node.type)) return
          // Calculate position of the context menu. We want to make sure it
          // doesn't get positioned off-screen.
          const pane = refFlow.current.getBoundingClientRect();
          setMenu({
            id: node.id,
            top: event.clientY < pane.height - 100 && event.clientY,
            left: event.clientX - 360 < pane.width - 100 && event.clientX - 360,
            right: event.clientX - 360 >= pane.width - 100 && pane.width - (event.clientX - 360),
            bottom: event.clientY >= pane.height - 100 && pane.height - event.clientY,
          });
        },
        [setMenu],
      );
    // const { getNode, addNodes , getNodes, getEdges } = useReactFlow();
    const onPaneClick = useCallback(() => setMenu(null), [setMenu]);

    const duplicateNode = useCallback((id) => {
        const nodesDefault = ["nodeStart", "nodeVariables", 'nodeComment']
        if (id) {
          const node = reactFlowInstance.getNode(id);
          const cloneNode = cloneDeep(node)
          for (const node of nodes) {
            node.selected = false
          }
          setNodes(nodes)
          const position = {
            x: node.position.x + 50,
            y: node.position.y + 50,
          };
          setNodes((nds) => nds.concat({ ...cloneNode, id: uuid(), position, selected: true }))
        } else {
          const selectedNodes = nodes.filter((nds) => nds.selected && !nodesDefault.includes(nds.type))
          const cloneNodes = cloneDeep(selectedNodes)
          const selectedEdges = edges.filter(eds => eds.selected)
          const uuidMapping = {}
          const nodeToDuplicate = cloneNodes.filter(node => !nodesDefault.includes(node.type)).map(node => {
            const newUuid = uuid()
            uuidMapping[node.id] = newUuid
            return {
              ...node,
              id: newUuid,
              position: {
                x: node.position.x + 50,
                y: node.position.y + 50,
              },
            }
          })
          const newSelectedEdges = selectedEdges.filter(eds => selectedNodes.some(nds => nds.id === eds.source && !nodesDefault.includes(nds.type)) && selectedNodes.some(nds => nds.id === eds.target && !nodesDefault.includes(nds.type)))
          const edgeToDuplicate = newSelectedEdges.map(edge => ({
            source: uuidMapping[edge.source] ? uuidMapping[edge.source] : edge.source,
            target: uuidMapping[edge.target] ? uuidMapping[edge.target] : edge.target,
            selected: true,
            targetHandle: edge.targetHandle.replace(edge.target, uuidMapping[edge.target]),
            sourceHandle: edge.sourceHandle.replace(edge.source, uuidMapping[edge.source]),
            type: "customEdge",
            id: `reactflow__edge-${uuid()}`,
            data: { setEdges },
          }))
          for (const edge of selectedEdges) {
            edge.selected = false
          }
          for (const node of selectedNodes) {
            node.selected = false
          }
          const nodeRemains = nodes.filter(n => !selectedNodes.some(on => n.id === on.id)).map(item => ({ ...item, selected: false}))
          const newNodes = [...nodeRemains, ...selectedNodes, ...nodeToDuplicate]
          setNodes(newNodes)
          const edgeRemains = edges.filter(fil => !selectedEdges.some(x => x.id === fil.id)).map(item => ({ ...item, selected: false}))
          setEdges([...edgeRemains, ...selectedEdges, ...edgeToDuplicate])
        }
      }, [nodes, edges]);

    const deleteNode = useCallback((id) => {
        const nodesDefault = ["nodeStart", "nodeVariables"]
        const nodeToRemoves = nodes.filter((nds) => nds.selected && !nodesDefault.includes(nds.type)).map(n => n.id)
        if (!isEmpty(nodeToRemoves)) {
          setNodes((nds) => nds.filter((node) => !nodeToRemoves.includes(node.id)))
        } else {
          setNodes((nds) => nds.filter((node) => node.id !== id));
          setEdges((edges) => edges.filter((edge) => edge.source !== id));
        }
      }, [nodes]);

      const copyNode = useCallback(() =>{
        const nodesDefault = ["nodeStart", "nodeVariables", "nodeComment"]
        const cloneNodes = cloneDeep(nodes)
        const nodeToCopied = cloneNodes.filter((nds) => nds.selected && !nodesDefault.includes(nds.type))
        const edgeToCopied = edges.filter((nds) => nds.selected)
        if (!isEmpty(nodeToCopied)) {
            console.log({nodeToCopied});
            storeFlow.current = {
                node: nodeToCopied,
                edge: edgeToCopied,
            }
        }
      },[nodes])

      const isHasNode = (nodes, nodeToCheck) => {
        return nodes.some(n => n.id === nodeToCheck.id)
      }

      const pasteNode = useCallback((pasteNode, event) =>{
        if (isEmpty(storeFlow.current.node)) return
        const selectedNodes = cloneDeep(storeFlow.current.node)
        const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
        const oldEdges = cloneDeep(edges)
        const newSelectedEdges = storeFlow.current.edge.filter(eds => selectedNodes.some(nds => nds.id === eds.source) && selectedNodes.some(nds => nds.id === eds.target))
        let newNode = []
        const uuidMapping = {}
        const { left, top } = reactFlowBounds
        if (pasteNode && storeFlow.current.node.length && event) {
            const { clientX: mouseX, clientY: mouseY } = event
            const firstNodeToPaste = storeFlow.current.node.reduce((previous, current) => {
                return current.position.y < previous.position.y ? current : previous;
            });
            const positionFNodeX = parseInt(firstNodeToPaste.position.x)
            const positionFNodeY = parseInt(firstNodeToPaste.position.y)
            const zoom = reactFlowInstance.getZoom()
            newNode = selectedNodes.map(nds => {
                const newUuid = uuid()
                uuidMapping[nds.id] = newUuid
                return {
                    ...nds,
                    id : newUuid,
                    data: cloneDeep(nds.data),
                    position : reactFlowInstance.project({
                        x: (mouseX + (nds.position.x - positionFNodeX)*zoom - left),
                        y: (mouseY + (nds.position.y - positionFNodeY)*zoom - top),
                    }),
                    selected: true
                }
            })
        } else {
            newNode = selectedNodes.map(nds => {
                const newUuid = uuid()
                uuidMapping[nds.id] = newUuid
                return {
                    ...nds,
                    data: cloneDeep(nds.data),
                    id : newUuid,
                    position: {
                        x: nds.position.x + 50,
                        y: nds.position.y + 50,
                    },
                    selected: true
                }
            })
        }
        const edgeToPaste = newSelectedEdges.map(edge => {
            return {
                source: uuidMapping[edge.source] ? uuidMapping[edge.source] : edge.source,
                target: uuidMapping[edge.target] ? uuidMapping[edge.target] : edge.target,
                selected: true,
                targetHandle: edge.targetHandle.replace(edge.target, uuidMapping[edge.target]),
                sourceHandle: edge.sourceHandle.replace(edge.source, uuidMapping[edge.source]),
                type: "customEdge",
                id: `reactflow__edge-${uuid()}`,
                data: { setEdges },
              }
        })
        for (const node of selectedNodes) {
            node.selected = false
        }
        for (const edge of oldEdges) {
            edge.selected = false
        }

        const edgeRemains = edges.filter(fil => !oldEdges.some(x => x.id === fil.id)).map(item => ({ ...item, selected: false}))
        const nodeRemains = nodes.filter(n => !selectedNodes.some(on => n.id === on.id)).map(item => ({ ...item, selected: false}))
        const newSelectedNode = selectedNodes.filter(node => isHasNode(nodes, node))
        setNodes([...nodeRemains, ...newNode, ...newSelectedNode])
        setEdges([...edgeRemains, ...oldEdges, ...edgeToPaste])
      },[nodes])

      useEffect(() => {
        const handleKeyDown = (event) => {
            // event.preventDefault();
            if (showModal) return
            const { value } = event.target
            const code = event.which || event.keyCode;
            let charCode = String.fromCharCode(code).toLowerCase();
            const selectNodes = nodes.filter(nds => nds.selected)
            if ((event.ctrlKey || event.metaKey) && charCode === 'd') {
                if(!isEmpty(selectNodes)) {
                    duplicateNode()
                }
            } else if ((event.key === 'Backspace' || event.key === 'Delete') && value === undefined) {
                if(!isEmpty(selectNodes)) {
                    deleteNode()
                }
            } else if ((event.ctrlKey || event.metaKey) && charCode === 'z') {
                onRestore()
            } else if ((event.ctrlKey || event.metaKey) && charCode === 'c') {
                copyNode()
            } else if ((event.ctrlKey || event.metaKey) && charCode === 'v') { 
                pasteNode()
            } else if ((event.ctrlKey || event.metaKey) && charCode === 's') { 
                onSave()
            }
        };
        window.addEventListener('keydown', handleKeyDown);
        return () => window.removeEventListener('keydown', handleKeyDown);
      }, [nodes, showModal]);

      const onSave = useCallback(() => {
        if (reactFlowInstance) {
          const flow = reactFlowInstance.toObject();
          if (restore.current.length >= 10) restore.current.shift()
          const newRestore = [...restore.current, flow]
          restore.current = newRestore
        }
      }, [reactFlowInstance]);

      const onRestore = useCallback(() => {
        const restoreFlow = async () => {
            const flow = restore.current.pop()
            if (flow) {
                const { x = 0, y = 0, zoom = 1 } = flow.viewport;
                setNodes(flow.nodes || []);
                setEdges(flow.edges || []);
                setViewport({ x, y, zoom });
            }
        };
        restoreFlow();
    }, [setNodes, setViewport, restore.current]);

    return (
        <>
            <div className="content">
                <div className="dndflow">
                        <ReactFlowProvider>
                            <div className="reactflow-wrapper" ref={reactFlowWrapper}>
                                <ReactFlow
                                    ref={refFlow}
                                    nodes={nodes}
                                    edges={edges}
                                    onNodesChange={onNodesChange}
                                    onEdgesChange={onEdgesChange}
                                    onConnect={onConnect}
                                    onInit={setReactFlowInstance}
                                    onDrop={onDrop}
                                    onDragOver={onDragOver}
                                    // fitView
                                    nodeTypes={nodeTypes}
                                    edgeTypes={edgeTypes}
                                    onNodeDoubleClick={onClickNode}
                                    defaultViewport={defaultViewport}
                                    deleteKeyCode={null}
                                    onPaneClick={onPaneClick}
                                    onNodeContextMenu={onNodeContextMenu}
                                    onSelectionContextMenu={onSelectionContextMenu}
                                    onPaneContextMenu={onPaneContextMenu}
                                >
                                    <Background color="#99b3ec" variant={'dots'} />
                                    <ControlsStyled />
                                    <MiniMapStyled
                                        nodeStrokeWidth={3}
                                        zoomable pannable
                                    />
                                    {menu && 
                                    <ContextMenu 
                                        onClick={onPaneClick}
                                        deleteNode={deleteNode}
                                        duplicateNode={duplicateNode}
                                        copyNode={copyNode}
                                        pasteNode={pasteNode}
                                        {...menu} 
                                    />}
                                </ReactFlow>
                            </div>
                        </ReactFlowProvider>
                        <div 
                            className={classes.action} >
                                <Group position="center" spacing="sm" style={{ flexWrap: 'nowrap' }}>
                                    <ActionStatus
                                        props={props}
                                        nodes={nodes}
                                    />
                                </Group>
                        </div>
                </div>
                {showModal &&
                    <BaseModal
                        show={showModal}
                        setShowModal={setShowModal}
                        bodyModal={GetBodyModalComponent[nodeData?.data?.type]}
                        documentModal={GetDocumentModalComponent[nodeData?.data?.type]}
                        nodeData={nodeData}
                        updateDataInNode={updateDataInNode}
                    />
                }
            </div>
        </>
    );
};

export default forwardRef(AutomationFlow);
