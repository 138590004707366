import React, { useState, useEffect } from 'react';
import {Box, Flex, NumberInput, Radio, Select, TextInput} from '@mantine/core';
import VariableSelectWrite from "../../../GlobalComponent/VariableSelectWrite";
import VariableNumberInput from "../../../GlobalComponent/VariableNumberInput";

const MouseMovement = (props) => {
    const [dataState, setDataState] = useState(props?.nodeData?.data?.options ?? {
        'selectorType': 'selector',
        'elementSelect': '',
        'elementOrder': 'fixed',
        'fixedElement': 1,
        'randomElementFrom': 1,
        'randomElementTo': 50,
        'x': 0,
        'y': 0,
        focus: false
    });

    const handleSelector = (type, value) => {
        if (typeof value === 'string' || typeof value === 'number') {
            setDataState({...dataState, [type]: value});
        } else if (typeof value === 'boolean') {
            setDataState({...dataState, [type]: value});
        } else {
            setDataState({...dataState, [type]: value.target.value});
        }
    }

    useEffect(() => {
        console.log(dataState)
        props.handleSetDataBaseModal(dataState)
    }, [props, dataState])

    return (
        <Box>
            <Radio.Group
                value={dataState.selectorType}
                label="Choose type"
                onChange={(e) => {
                    handleSelector('selectorType', e)
                }}
                name="element-exists"
            >
                <Radio value="selector" label="Selector" />
                <Radio value="coordinates" label="Coordinates" />
            </Radio.Group>

            {dataState.selectorType === "selector" &&
                <>
                    <VariableSelectWrite
                        style={{marginBottom:"10px"}}
                        dataState={dataState.elementSelect} label="Select element" placeholder="Please enter the element, such as #email input"
                        setDataState={handleSelector}
                        handleData={(e) => {
                            handleSelector('elementSelect', e)
                        }}
                        handleSelect={(e) => {
                            const value = e.target.getAttribute('value')
                            handleSelector('elementSelect', dataState.elementSelect + "${" + value + "}")
                        }}
                    />
                    <Flex
                        mih={50}
                        gap="md"
                        justify="flex-start"
                        align="flex-start"
                        direction="row"
                        wrap="wrap"
                    >
                        <Select
                            value={dataState.elementOrder}
                            label="Element order"
                            placeholder="Pick one"
                            style={{width:"150px"}}
                            data={[
                                { value: 'fixed', label: 'Fixed Value' },
                                { value: 'random', label: 'Random Value' },
                            ]}
                            onChange={(e) => {
                                handleSelector('elementOrder', e)
                            }}
                        />
                        {dataState.elementOrder === "fixed" &&
                            <VariableNumberInput
                                style={{width:"200px"}}
                                label=" "
                                dataState={dataState.fixedElement}
                                handleData={(e) => {
                                    handleSelector('fixedElement', e)
                                }}
                            />
                        }
                        {dataState.elementOrder === "random" &&
                            <>
                                <NumberInput
                                    style={{width:"150px"}}
                                    value={dataState.randomElementFrom}
                                    label="from"
                                    min={1}
                                    onChange={(e) => {
                                        handleSelector('randomElementFrom', e)
                                    }}
                                />
                                <NumberInput
                                    style={{width:"150px"}}
                                    value={dataState.randomElementTo}
                                    label="to"
                                    min={1}
                                    onChange={(e) => {
                                        handleSelector('randomElementTo', e)
                                    }}
                                />
                            </>
                        }
                    </Flex>

                    <Select
                        value={dataState.focus}
                        label="Focus"
                        placeholder="Pick one"
                        style={{width:"150px", marginTop:"10px"}}
                        data={[
                            { value: false, label: 'False' },
                            { value: true, label: 'True' },
                        ]}
                        onChange={(e) => {
                            handleSelector('focus', e)
                        }}
                    />
                </>
            }
            {dataState.selectorType === "coordinates" &&
                <>
                    <VariableSelectWrite
                        label="Enter X coordinate"
                        dataState={dataState.x}
                        handleData={(e) => {
                            handleSelector('x', e);
                        }}
                        handleSelect={(e) => {
                            const value = e.target.getAttribute('value')
                            handleSelector('x', dataState.x + "${" + value + "}");
                        }}
                    />
                    <VariableSelectWrite
                        label="Enter Y coordinate"
                        dataState={dataState.y}
                        handleData={(e) => {
                            handleSelector('y', e);
                        }}
                        handleSelect={(e) => {
                            const value = e.target.getAttribute('value')
                            handleSelector('y', dataState.y + "${" + value + "}");
                        }}
                    />
                </>
            }
        </Box>
    );
}

export default MouseMovement;
