import React, { useState } from "react";
import { MultiSelect } from "@mantine/core";
import { pressKey } from "./dataPressKey";

const PressKey = (props) => {
    const [selectKeys, setSelectKeys] = useState(props?.nodeData?.data?.options?.pressKey ?? []);

    const handleSelectPressKeys = (value) => {
        setSelectKeys(value);
        props.handleSetDataBaseModal({ pressKey: value });
    }

    return (
        <MultiSelect
            data={pressKey}
            placeholder="Keys"
            label="Select key(s)"
            searchable
            nothingFound="Nothing found"
            value={selectKeys}
            onChange={handleSelectPressKeys}
        />
    )
}
export default PressKey;