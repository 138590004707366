import React, { useEffect, useState } from "react";
import VariableSelect from "../../../GlobalComponent/VariableSelect";
import VariableTextarea from "../../../GlobalComponent/VariableTextarea";
import VariableSelectCreate from './../../../GlobalComponent/VariableSelectCreate';

const placeholder = `
// Other codes...
const result = 1
return result
`

const EvalCode = (props) => {
    const [dataState, setDataState] = useState(props?.nodeData?.data?.options??{
        scriptCode: "",
        outputVariable: "",
        injectVariable: []
    });

    const handleChange = (key, target) => e => {
        if (e?.type === 'change') {
            setDataState((state) => ({...state, [key]: target ? e.target[target] : e.target.value}))
        } else if (e?.type === 'click') {
            const value = e.target.getAttribute('value')
            const newValue = dataState[key] + "${" + value + "}"
            setDataState((state) => ({...state, [key]: newValue}))
        } else {
            setDataState((state) => ({...state, [key]: e}))
        }
    }

    useEffect(() => {
        props.handleSetDataBaseModal(dataState)
    }, [props, dataState])

    return (
        <>
            <p style={{ fontSize: 14 }}>
                {`(function (${dataState.injectVariable?.join(', ')}) {`}
            </p>
            <VariableTextarea
                style={{marginTop: 0}}
                label=""
                placeholder={placeholder}
                dataState={dataState.scriptCode}
                handleData={handleChange("scriptCode")}
                handleSelect={handleChange('scriptCode')}
            />
            <p style={{ fontSize: 14 }}>
                {`})(${dataState.injectVariable?.join(', ')})`}
            </p>
            <VariableSelect
                style={{marginTop: "20px"}}
                label="Inject variables"
                value={dataState.injectVariable}
                handleChange={handleChange('injectVariable')}
                multiple={true}
            />
            <VariableSelectCreate
                style={{marginTop: "20px"}}
                label="Output Variable"
                value={dataState.outputVariable}
                handleChange={handleChange('outputVariable')}
            />
        </>
    );
}

export default EvalCode;