import {createContext, useState} from "react";

const AppSettingsContext = createContext();
function AppSettingsProvider({children}) {
    const [settings, setSettings] = useState({ language: 'en', autoVersion: 0, isCloud: true, updateId: '' });

    const value = {
        settings,
        setSettings,
    }

    return (
        <AppSettingsContext.Provider value={value}>
            {children}
        </AppSettingsContext.Provider>
    )
}

export {AppSettingsContext, AppSettingsProvider}