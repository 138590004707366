import {createContext, useState} from "react";
import {v4 as uuid} from "uuid";

const DataFlowContext = createContext();
function DataFlowProvider({children}) {
    const [comment, setComment] = useState({});

    const [nodeValue, setNodeValue] = useState({
        variables: [
            {
                id: uuid(),
                value: 'PROFILE_NAME',
                label: 'PROFILE_NAME',
                data: "",
                type: 'disabled',
            },
            {
                id: uuid(),
                value: 'PROFILE_ID',
                label: 'PROFILE_ID',
                data: "",
                type: 'disabled',
            }
        ]
    })

    const value = {
        comment,
        nodeValue,
        setComment,
        setNodeValue
    }

    return (
        <DataFlowContext.Provider value={value}>
            {children}
        </DataFlowContext.Provider>
    )
}

export {DataFlowContext, DataFlowProvider}