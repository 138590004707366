export const replaceVariablesWithValue = (command_data, variablesArray) => {
    const variables = {}
    for (const varItem of variablesArray) {
        variables[varItem.label] = varItem.data
    }
    let new_cmd_data = { ...command_data };
    Object.keys(new_cmd_data).map(function (d_i) {
        const regex = /(\${\w+})+?/gm;
        let str_data = new_cmd_data[d_i];
        if (typeof new_cmd_data[d_i] === "object" && !!new_cmd_data[d_i]) {
            new_cmd_data[d_i] = replaceVariablesWithValue(new_cmd_data[d_i], variablesArray);
        } else {
            let m;
            let data_rs = str_data;
            while ((m = regex.exec(str_data)) !== null) {
                if (m.index === regex.lastIndex) {
                    regex.lastIndex++;
                }
                if (m[1]) {
                    console.log("m[1]", m[1]);
                    const variable_key = m[1].replace("${", "").replace("}", "");
                    let replace_value = "";
                    if (typeof variables[variable_key] !== "undefined") {
                        replace_value = variables[variable_key];
                    } else {
                        replace_value = "";
                    }
                    data_rs = data_rs.replace(m[1], replace_value?.toString().trim());
                }
            }
            new_cmd_data[d_i] = data_rs;
        }
    });
    return new_cmd_data;
}
