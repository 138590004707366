import { ActionIcon, Popover, TextInput, Textarea } from "@mantine/core";
import React, { useContext, useState } from "react";
import { IconCode } from "@tabler/icons";
import { DataFlowContext } from "../../core/context/DataFlowContext";

const VariableSelectWrite = ({ label, placeholder, dataState, handleData, handleSelect, description, className, style, disabled=false }) => {
    const [openDropdown, setOpenDropdown] = useState(false)
    const dataSelect = useContext(DataFlowContext)
    const handleDropdown = () => {
        setOpenDropdown(!openDropdown)
    }
    const rightSection = (<ActionIcon onClick={handleDropdown} variant="filled" color="yellow" ><IconCode size={16} /></ActionIcon>)
    return (
        <>
            <Popover disabled={disabled} opened={openDropdown} width="target" position="bottom" shadow="md">
                <Popover.Target>
                    <Textarea
                        style={style}
                        className={className}
                        placeholder={placeholder}
                        value={dataState}
                        label={label}
                        mt="xs"
                        description={description}
                        onChange={handleData}
                        rightSection={rightSection}
                        autosize
                        minRows={2}
                        disabled={disabled}
                    />
                </Popover.Target>
                <Popover.Dropdown style={{ maxHeight: '40vh', overflow: 'auto' }}>
                    {dataSelect.nodeValue.variables.map((ele, index) => {
                        return (
                            <li
                                key={index}
                                onClick={(e) => handleSelect(e)}
                                value={ele.value}
                            >
                                {ele.label}
                            </li>
                        )
                    })}
                </Popover.Dropdown>
            </Popover>
        </>
    )
}

export default VariableSelectWrite;