import { Box, Button, SegmentedControl, Select, Switch } from "@mantine/core";
import { IconFolder } from "@tabler/icons";
import React, { useEffect, useState } from "react";
import VariableSelectWrite from "../../../GlobalComponent/VariableSelectWrite";

const FORMAT_IMAGE = [
    { value: "png", label: "PNG" },
    { value: "jpeg", label: "JPEG" },
]

const ScreenShot = (props) => {
    const [dataState, setDataState] = useState(props?.nodeData?.data?.options ?? {
        screen_shot_name: Number(new Date()).toString(),
        path_folder: "",
        isFullScreen: false,
        modeSave: "default",
        type_format: "jpeg"
    });

    const onChangeValue = (key, target) => e => {
        if (e?.type === 'change') {
            setDataState((state) => ({...state, [key]: target ? e.target[target] : e.target.value}))
        } else if (e?.type === 'click') {
            const value = e.target.getAttribute('value')
            const newValue = dataState[key] + "${" + value + "}"
            setDataState((state) => ({...state, [key]: newValue}))
        } else {
            setDataState((state) => ({...state, [key]: e}))
        }
    }
    const onSelectFolder = async () => {
        const path = await window.electronAPI.getDictionary()
        setDataState(state => ({...state, path_folder: path }))
    }

    const onChangeFolderMode = (value) => {
        setDataState((state) => ({ ...state, modeSave: value }))
    }

    useEffect(() => {
        props.handleSetDataBaseModal(dataState)
    }, [props, dataState])

    return (
        <>
            <VariableSelectWrite
                label="Screenshot name"
                dataState={dataState.screen_shot_name || ""}
                handleData={onChangeValue('screen_shot_name')}
                handleSelect={onChangeValue('screen_shot_name')}
            />
            
            <label
                className="mantine-InputWrapper-label mantine-Textarea-label mantine-ittua2"
                style={{ display: 'block', marginTop: 12 }}
            >
                Save to folder
            </label>
            <SegmentedControl
                data={[
                    { label: 'Default', value: "default" },
                    { label: 'Select Folder', value: "custom" },
                ]}
                value={dataState.modeSave}
                onChange={onChangeFolderMode}
            />
            {
                dataState.modeSave === "custom" ? (
                    <div style={{ display: 'flex', alignItems: 'end' }}>
                        <VariableSelectWrite
                            style={{ flex: 1, marginRight: 8 }}
                            placeholder="Enter full path or upload file"
                            label=""
                            dataState={dataState.path_folder}
                            handleData={onChangeValue("path_folder")}
                            handleSelect={onChangeValue("path_folder")}
                        />
                        <Button onClick={onSelectFolder} leftIcon={<IconFolder size="1rem" />}>
                            Select
                        </Button>
                    </div>
                ) : (
                    <Box
                        sx={(theme) => ({
                            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
                            textAlign: 'center',
                            padding: theme.spacing.sm,
                            borderRadius: theme.radius.md,
                            marginTop: 8,
                        })}
                    >
                        Save in Pictures folder
                    </Box>
                )
            }
            <Switch
                label="Take a full screen"
                checked={dataState.isFullScreen}
                style={{ marginTop: 12 }}
                onChange={e => setDataState(s => ({ ...s, isFullScreen: e.target.checked }))}
            />

            <label className="mantine-InputWrapper-label mantine-Textarea-label mantine-ittua2">Fomat</label>
            <Select
                value={dataState.type_format || ""}
                placeholder="Pick one"
                clearable
                data={FORMAT_IMAGE}
                onChange={val => setDataState((s) => ({...s, type_format: val}))}
            />
        </>
    );
}

export default ScreenShot;
