import React, { memo } from 'react';
import { Handle } from 'reactflow';
import LoadIcon from '../../Icon/LoadIcon';
import './Loop.css'
import { ThemeIcon, Text, ActionIcon } from '@mantine/core';
import { IconEdit, IconTrash } from '@tabler/icons';

const Loop = ({ id, data, isConnectable }) => {

    const handleRemoveNode = (e) => {
        e.stopPropagation();
        data.onRemoveNode(id);
    }

    const handleOpenNode = (e) => {
        e.stopPropagation();
        data.onUpdateNode(id)
    }

    return (
        <div className="node-loop">
            <div className="node-loop-inside">
                {/* <ActionIcon onClick={handleRemoveNode} className="remove-node-loop" color="red" size="sm" radius="xl">
                    <IconX size={14} />
                </ActionIcon> */}
                <div className='action-node'>
                    <ActionIcon onClick={handleRemoveNode} color="red" size="sm" radius="xl">
                        <IconTrash size={14} />
                    </ActionIcon>
                    <ActionIcon onClick={handleOpenNode} color="blue" size="sm" radius="xl">
                        <IconEdit size={14} />
                    </ActionIcon>
                </div>
                <Handle
                    id={`out-${id}`}
                    type="source"
                    position="left"
                    style={{ background: "var(--source)", left: "-32px", top: "40%" }}
                    isConnectable={isConnectable}
                />
                <div className='node-normal-body-loop'>
                    <ThemeIcon className="node-icon">
                        <LoadIcon icon={data.icon} size={16} />
                    </ThemeIcon>
                    <Text size="0.8rem">{data.label}</Text>
                </div>
                <Handle
                    id={`above-${id}`}
                    type="target"
                    position="left"
                    isConnectable={isConnectable}
                    style={{ background: "var(--input)", left: "30px", top: "-50px" }}
                />
                <Handle
                    id={`below-${id}`}
                    type="target"
                    position="right"
                    isConnectable={isConnectable}
                    style={{ background: "var(--input)", right: "39px", top: "70px" }}
                />
                <Handle
                    id={`success-${id}`}
                    type="source"
                    position="right"
                    style={{ background: "var(--output)", top: "-10px"}}
                    isConnectable={isConnectable}
                />
                <Handle
                    id={`error-${id}`}
                    type="source"
                    position="right"
                    style={{ background: "var(--output-bottom)", top: "32px" }}
                    isConnectable={isConnectable}
                />
            </div>
        </div>
    );
}

export default memo(Loop);
