import React, {useState,useEffect} from "react";
import {Grid, NumberInput, Radio} from "@mantine/core";

const MAX_INT_32 = 2147483647 // Nodejs Timeout overflow: max: INT32

const PauseCode = (props) => {
    const [dataState, setDataState] = useState(props?.nodeData?.data?.options ?? {
        typePauseState:"",
        description: "",
        exactTimeState:0,
        fromTimeState:0,
        toTimeState:0
    });

    const handleSelector = (type, value) => {
        if (typeof value === 'string') {
            setDataState({...dataState, [type]: value});
        } else if (typeof value === 'object' && value === null) {
            setDataState({...dataState, [type]: ""});
        } else {
            setDataState({...dataState, [type]: value});
        }
    }

    const resetData = (e) =>{
        if(e == "interval"){
            handleSelector('exactTimeState',0);
        }else{
            handleSelector('fromTimeState',0);
            handleSelector('toTimeState',0);
        }
        handleSelector('typePauseState', e);
    }
    useEffect(() => {
        const newData = { ...dataState }
        if (newData.typePauseState === "exactTime") {
            newData.description = `${newData.exactTimeState || 0} ms`
        } else {
            newData.description = `${newData.fromTimeState || 0} - ${newData.toTimeState || 0} ms`
        }
        props.handleSetDataBaseModal(newData)
    }, [props, dataState])
    return (
        <>
            <Radio.Group
                value={dataState.typePauseState}
                onChange={resetData}
                name="typePause"
                label="Choose one"
                mb="10px"
            >
                <Radio value="exactTime" label="Exact time"/>
                <Radio value="interval" label="Interval" />
            </Radio.Group>
            {dataState.typePauseState==='exactTime'?
                <NumberInput
                    placeholder="Enter milliseconds"
                    description="1 s = 1000 ms"
                    label="Enter milliseconds"
                    name="exactTime"
                    max={MAX_INT_32}
                    value={dataState.exactTimeState}
                    onChange={(e) => {
                        handleSelector('exactTimeState', e)
                    }}
                    min={0}
                />
                :
                <Grid>
                    <Grid.Col span={6}>
                        <NumberInput
                            placeholder="Enter milliseconds"
                            description="1 s = 1000 ms"
                            label="From (milliseconds)"
                            name="fromTime"
                            value={dataState.fromTimeState}
                            max={MAX_INT_32}
                            onChange={(e) => {
                                handleSelector('fromTimeState', e)
                            }}
                            min={0}
                        />
                    </Grid.Col>
                    <Grid.Col span={6}>
                        <NumberInput
                            placeholder="Enter milliseconds"
                            description="1 s = 1000 ms"
                            label="To (milliseconds)"
                            name="toTime"
                            max={MAX_INT_32}
                            value={dataState.toTimeState}
                            onChange={(e) => {
                                handleSelector('toTimeState', e)
                            }}
                            min={0}
                        />
                    </Grid.Col>
                </Grid>
            }
        </>
    );
}

export default PauseCode;