import { useEffect, useState } from "react";
import VariableSelectWrite from "../../../GlobalComponent/VariableSelectWrite";

const MouseWheel = (props) => {
    const [dataState, setDataState] = useState(props?.nodeData?.data?.options ?? { 
        deltaX: 0,
        deltaY: 0
    })

    const handleSelector = (type, value) => {
        if (typeof value === 'string' || typeof value === 'number') {
            setDataState({...dataState, [type]: value});
        } else {
            setDataState({...dataState, [type]: value.target.value});
        }
    }

    useEffect(() => {
        props.handleSetDataBaseModal(dataState)
    }, [props, dataState])

    return (
        <>
            <VariableSelectWrite
                label="Enter X coordinate"
                dataState={dataState.deltaX}
                handleData={(e) => {
                    handleSelector('deltaX', e);
                }}
                handleSelect={(e) => {
                    const value = e.target.getAttribute('value')
                    handleSelector('deltaX', dataState.deltaX + "${" + value + "}");
                }}
            />
            <VariableSelectWrite
                label="Enter Y coordinate"
                dataState={dataState.deltaY}
                handleData={(e) => {
                    handleSelector('deltaY', e);
                }}
                handleSelect={(e) => {
                    const value = e.target.getAttribute('value')
                    handleSelector('deltaY', dataState.deltaY + "${" + value + "}");
                }}
            />
        </>
    )
}
export default MouseWheel