import React, { memo } from 'react';
import { Handle } from 'reactflow';
import { IconPlayerPlay } from '@tabler/icons';
import { Text } from '@mantine/core';
import './Start.css'

const Start = ({ id, data, isConnectable }) => {

    return (
        <>
            <div className="node-start">
                <IconPlayerPlay size={16} />
                <Text size="0.8rem">{data.label}</Text>
                <Handle
                    id={`out-${id}`}
                    type="source"
                    position="right"
                    isConnectable={isConnectable}
                    style={{ background: "var(--source)", right: "-6px" }}
                />
            </div>

        </>
    );
}

export default memo(Start);