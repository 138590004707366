import { createContext, useContext, useEffect, useState } from "react";
import { listProfileName } from "../../request/Automation";
import { AppSettingsContext } from "./AppSettingsContext";

const ListProfilesContext = createContext()

function ListProfileProvider({children}) {
    const [search, setSearch] = useState('')
    const [listProfile, setListProfile] = useState([])
    const [valueChoose, setValueChoose] = useState('')
    const settingContext = useContext(AppSettingsContext)
    let isCloud = settingContext.settings.isCloud
    
    useEffect(() => {
        const limit = 20
        console.log(settingContext.setting);
        listProfileName(limit, search, isCloud ).then(res => {
            const fetchProfileName = res.data
            let dataProfile = [];
            fetchProfileName.data.content.map(item => {
                dataProfile.push({ label: item.name, value: item.uuid });
            });
            setListProfile(dataProfile);
        })
    }, [search, settingContext])

    const value = {
        search,
        listProfile,
        valueChoose,
        setSearch,
        setListProfile,
        setValueChoose,
    }

    return ( 
        <ListProfilesContext.Provider value={value}>
            {children}
        </ListProfilesContext.Provider>
     );
}

export { ListProfilesContext, ListProfileProvider };