import { Button, Container, Input, Modal, Text, useMantineTheme } from "@mantine/core"
import { useForm } from "@mantine/form";
import React, { useContext, useEffect } from "react"
import { ScriptContext } from "../context/ScriptContext";
import { ListProfilesContext } from "../context/ListProfilesContext";
import ComponentInputSearch from "../../components/GlobalComponent/ComponentInputSearch";

const ModalStatus = ({ show, setShowModal, handleSaveAs, handleError, handleSubmit, modalName, setUuidProfile, handleStart }) => {
    const theme = useMantineTheme();
    const scriptContext = useContext(ScriptContext);
    const { listProfile, valueChoose, setValueChoose, setSearch } = useContext(ListProfilesContext)
    const form = useForm({
        initialValues: { name: scriptContext.scriptName ?? '' },

        // functions will be used to validate values at corresponding key
        validate: {
            name: (value) => (value.length < 1
                ? 'Name must have at least 1 letters'
                : value.length > 255
                    ? 'Name is too long'
                    : null
            ),
        },
    });

    const handleClose = () => {
        setShowModal(false);
    }

    const handleSetValue = (item) => {
        if (typeof item === 'string') {
            setValueChoose(item)
            setSearch(item)
        } else if (typeof item === 'object') {
            setValueChoose(item.label)
        }
        let browserName = listProfile.find(ele => ele.value === item?.value)
        setUuidProfile({uuid : item?.value, browser_name: browserName?.label})
    }
    
    return (
        <Modal
            zIndex={1000}
            opened={show}
            onClose={handleClose}
            overlayColor={theme.colorScheme === 'dark' ? theme.colors.dark[9] : theme.colors.gray[2]}
            title={<Text fw={500} fz="xl">{modalName.title}</Text>}
            overlayOpacity={0.55}
            overlayBlur={0.5}
            size="lg"
        >
            <Container>
                {(() => {
                    switch (modalName.type) {
                        case 'Save':
                            return <div style={{ marginBottom: '12px' }}>
                                <Input.Wrapper
                                    label={modalName.label}
                                    id="name-script"
                                    withAsterisk
                                >
                                    <Input id="name-script" name='name-script'
                                        placeholder={modalName.placeholder} {...form.getInputProps('name')} />
                                </Input.Wrapper>

                                <form style={{ textAlign: 'end', marginTop: '20px' }} onSubmit={form.onSubmit(handleSubmit, handleError)}>
                                    <Button type="submit" variant="outline">Save</Button>
                                </form>
                            </div>
                        case 'Start':
                            return <div style={{ marginBottom: '12px' }}>
                                <ComponentInputSearch 
                                    listProfile={listProfile}
                                    placeholder="Choose a profile"
                                    label={modalName.label}
                                    withAsterisk={true}
                                    handleData={(e) => handleSetValue(e)}
                                    dataState={valueChoose}
                                    setUuidProfile={setUuidProfile}
                                />
                                <div style={{ textAlign: 'end', marginTop: '20px' }}>
                                    <Button onClick={handleStart} variant="outline" color="teal">Start</Button>
                                </div>
                            </div>
                        case 'SaveAs':
                            return <div style={{ marginBottom: "12px" }}>
                                <Input.Wrapper
                                    label={modalName.label}
                                    id="name-script"
                                    withAsterisk
                                >
                                    <Input id="name-script" name='name-script'
                                        placeholder={modalName.placeholder} {...form.getInputProps('name')} />
                                </Input.Wrapper>

                                <form style={{ textAlign: 'end', marginTop: '20px' }} onSubmit={form.onSubmit(handleSaveAs, handleError)}>
                                    <Button type="submit" variant="outline">SaveAs</Button>
                                </form>
                            </div>
                        default:
                            return null
                    }
                })()}
            </Container >
        </Modal >
    )
}
export default ModalStatus