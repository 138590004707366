import {createContext, useState} from "react";

const ScriptContext = createContext();
function ScriptProvider({children}) {
    const [scriptId, setScriptId] = useState(null);
    const [scriptName, setScriptName] = useState(null);

    const clearData = () => {
        setScriptId(null);
        setScriptName(null);
    }

    const value = {
        scriptId,
        scriptName,
        setScriptId,
        setScriptName,
        clearData
    }

    return (
        <ScriptContext.Provider value={value}>
            {children}
        </ScriptContext.Provider>
    )
}

export {ScriptContext, ScriptProvider}