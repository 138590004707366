import { Input, Radio } from "@mantine/core";
import { IconFileUpload } from "@tabler/icons";
import React, { useEffect, useRef, useState } from "react";
import VariableNumberInput from "../../../GlobalComponent/VariableNumberInput";
import VariableSelectWrite from "../../../GlobalComponent/VariableSelectWrite";

const defaultState = {
    elementSelect: "",
    fixedElement: 1,
    attachment: "localFile",
    filePath: '',
    folderPath: '',
    timeout: 30000,
}

const FileChoose = (props) => {
    const fileRef = useRef(null)
    const folderRef = useRef(null)
    const [dataState, setDataState] = useState(props?.nodeData?.data?.options ?? defaultState);

    const onChangeValue = (key, target) => (e) => {
        if (e?.type === "change") {
            setDataState((state) => ({
              ...state,
              [key]: target ? e.target[target] : e.target.value,
            }));
        } else if (e?.type === "click") {
            const value = e.target.getAttribute("value");
            const newValue = dataState[key] + "${" + value + "}";
            setDataState((state) => ({ ...state, [key]: newValue }));
        } else {
            setDataState((state) => ({ ...state, [key]: e }));
        }
    }

    useEffect(() => {
        props.handleSetDataBaseModal(dataState)
    }, [props, dataState])

    const handleUpload = (isFolder = false) => {
        if (isFolder) {
            setDataState({...dataState, filePath: folderRef.current.files[0].path.slice(0, folderRef.current.files[0].path.lastIndexOf('\\'))});
        }
        else {
            setDataState({...dataState, filePath: fileRef.current.files[0].path});
        }
    }

    const onSelectFolder = async (e) => {
        e.preventDefault()
        const path = await window.electronAPI.getDictionary()
        setDataState(state => ({...state, folderPath: path }))
    }

    return (
        <>
            <Radio.Group
                value={dataState.attachment}
                label="Choose selector type"
                onChange={onChangeValue('attachment')}
                withAsterisk
                name="element-exists"
            >
                <Radio value="localFile" label="Local file" />
                <Radio value="folderFileRandom" label="Folder file random" />
                <Radio value="networkFile" label="Network file" />
            </Radio.Group>
            {
                dataState.attachment === "localFile" &&
                <div className="custom-wrapper">
                    <div className="block-input">
                        <VariableSelectWrite
                            placeholder="Enter full path or upload file"
                            label="Path to the file"
                            dataState={dataState.filePath}
                            setDataState={onChangeValue('filePath')}
                            handleData={onChangeValue('filePath')}
                            handleSelect={onChangeValue('filePath')}
                        />
                    </div>
                    <Input
                        className="custom-file-input"
                        type="file"
                        ref={fileRef}
                        onChange={() => {
                            handleUpload(false)
                        }}
                        icon={<IconFileUpload size={16} />}
                        mt="0px"
                        styles={(theme) => ({
                            input: {
                                paddingRight: "0px",
                                '&::-webkit-file-upload-button': {
                                    visibility: 'hidden',
                                },
                                cursor: 'pointer',
                            },
                        })}
                    >
                    </Input>
                </div>
            }
            {
                dataState.attachment === "folderFileRandom" &&
                <div className="custom-wrapper">
                    <div className="block-input">
                        <VariableSelectWrite
                            placeholder="Enter full path or upload file"
                            label="Path to the folder"
                            dataState={dataState.folderPath}
                            setDataState={onChangeValue('folderPath')}
                            handleData={onChangeValue('folderPath')}
                            handleSelect={onChangeValue('folderPath')}
                        />
                    </div>
                    <Input
                        className="custom-file-input"
                        type="file"
                        onClick={onSelectFolder}
                        icon={<IconFileUpload size={16} />}
                        mt="0px"
                        styles={{ input: {
                            paddingRight: "0px",
                            '&::-webkit-file-upload-button': {
                                visibility: 'hidden',
                            },
                            cursor: 'pointer',
                        }}}
                    />
                </div>
            }
            {
                dataState.attachment === "networkFile" &&
                <VariableSelectWrite
                    placeholder="Enter link"
                    label="Path to the file"
                    dataState={dataState.filePath}
                    setDataState={onChangeValue('filePath')}
                    handleData={onChangeValue('filePath')}
                    handleSelect={onChangeValue('filePath')}
                />
            }
            <VariableNumberInput
                label="Timeout waiting (milliseconds)"
                dataState={dataState.timeout}
                handleData={onChangeValue('timeout')}
                isMillisecond={true}
            />
        </>
    )
}
export default FileChoose;