import {Box, Flex, NumberInput, Radio, Select, TextInput} from "@mantine/core";
import React, { useEffect, useState } from "react";
import VariableTextarea from "../../../GlobalComponent/VariableTextarea";
import VariableNumberInput from "../../../GlobalComponent/VariableNumberInput";
import VariableSelectWrite from "../../../GlobalComponent/VariableSelectWrite";

const ElementExists = (props) => {
    const [dataState, setDataState] = useState(props?.nodeData?.data?.options ?? {
        elementSelect: "",
        fixedElement: 1,
        isVisible:'true',
        timeout: 30000
    });

    const handleSelector = (type, value) => {
        if (typeof value === 'string' || typeof value === 'number') {
            setDataState({...dataState, [type]: value});
        } else {
            setDataState({...dataState, [type]: value.target.value});
        }
    }

    useEffect(() => {
        props.handleSetDataBaseModal(dataState)
    }, [props, dataState])

    return (
        <>
            <Box component="form" mx="auto">
                <VariableSelectWrite
                    style={{marginBottom:"10px"}}
                    dataState={dataState.elementSelect} label="Select element" placeholder="Please enter the element, such as #email input"
                    setDataState={handleSelector}
                    handleData={(e) => {
                        handleSelector('elementSelect', e)
                    }}
                    handleSelect={(e) => {
                        const value = e.target.getAttribute('value')
                        handleSelector('elementSelect', dataState.elementSelect + "${" + value + "}")
                    }}
                />
                <VariableNumberInput
                    style={{width:"200px"}}
                    label="Element order"
                    dataState={dataState.fixedElement}
                    handleData={(e) => {
                        handleSelector('fixedElement', e)
                    }}
                />
                <Select
                    value={dataState.isVisible}
                    label="Visible"
                    placeholder="Pick one"
                    style={{width:"150px", marginTop:"10px"}}
                    data={[
                        { value: 'false', label: 'False' },
                        { value: 'true', label: 'True' },
                    ]}
                    onChange={(e) => {
                        handleSelector('isVisible', e)
                    }}
                />
                <VariableNumberInput
                    label="Timeout waiting (milliseconds)"
                    dataState={dataState.timeout}
                    handleData={(e) => {
                        handleSelector('timeout',e)
                    }}
                    isMillisecond={true}
                />
            </Box>
        </>
    )
}

export default ElementExists;