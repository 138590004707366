import { useEffect, useState } from "react";
import { Grid, NumberInput } from "@mantine/core";

const MAX_INT_32 = 2147483647 // Nodejs Timeout overflow: max: INT32

function Setting(props) {
    const [dataState, setDataState] = useState(props?.nodeData?.data?.options?.nodeSetting ?? {
        fromTimeSleep: 0,
        toTimeSleep: 0
    })

    const handleSelector = (type, value) => {
        if (typeof value === 'string') {
            setDataState({...dataState, [type]: value});
        } else if (typeof value === 'object' && value === null) {
            setDataState({...dataState, [type]: ""});
        } else {
            setDataState({...dataState, [type]: value});
        }
    }

    useEffect(() => {
        props.handleSetDataNodeSetting(dataState)
    }, [props, dataState])

    return ( 
        <>
            <label className="mantine-InputWrapper-label mantine-Textarea-label mantine-ittua2">
                Sleep time (milliseconds) before running the next node.
            </label>
            <Grid mb='xs'>
                <Grid.Col span={6}>
                    <NumberInput
                        placeholder="Enter milliseconds"
                        description="1 s = 1000 ms"
                        label="From (milliseconds)"
                        name="fromTime"
                        value={dataState.fromTimeSleep}
                        max={MAX_INT_32}
                        onChange={(e) => {
                            handleSelector('fromTimeSleep', e)
                        }}
                        min={0}
                    />
                </Grid.Col>
                <Grid.Col span={6}>
                    <NumberInput
                        placeholder="Enter milliseconds"
                        description="1 s = 1000 ms"
                        label="To (milliseconds)"
                        name="toTime"
                        max={MAX_INT_32}
                        value={dataState.toTimeSleep}
                        onChange={(e) => {
                            handleSelector('toTimeSleep', e)
                        }}
                        min={0}
                    />
                </Grid.Col>
            </Grid>
        </>
    );
}

export default Setting;