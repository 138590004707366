import axios from 'axios';

const setupInterceptors = () => {

    //Handle request
    axios.interceptors.request.use(function (config) {
        let platform = ''
        try {
            platform = window.api.os.platform();
        } catch (error) {
            console.log(error);
        }
        //Add token after request
        const token = localStorage.getItem('token');
        if (token) {
            config.headers.Authorization = `${token}`
            config.headers['mt-os'] = platform;
        }
        config.headers.Accept = 'application/json';

        return config;

    }, function (error) {
        return Promise.reject(error);
    });

}

export default setupInterceptors;