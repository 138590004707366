import react, { useEffect, useState } from "react";
import VariableSelectWrite from "../../../GlobalComponent/VariableSelectWrite";

function TriggerExtension(props) {
  const [dataState, setDataState] = useState(props?.nodeData?.data?.options || {extensionId: ''})

  const onChangeValue = (key, target) => (e) => {
    if (e?.type === 'change') {
      setDataState((state) => ({...state, [key]: target ? e.target[target] : e.target.value}))
    } else if (e?.type === 'click') {
      const value = e.target.getAttribute('value')
      const newValue = dataState[key] + "${" + value + "}"
      setDataState((state) => ({...state, [key]: newValue}))
    } else {
      setDataState((state) => ({...state, [key]: e}))
    }
  }
  useEffect(() => {
    props.handleSetDataBaseModal(dataState)
  }, [props, dataState])

  return (
    <>
      <div style={{ display: "flex", alignItems: 'center' }}>
        <label style={{ fontWeight: 500, fontSize: 14 }}>Extension ID</label>
        {/* <Tooltip label={SHEET_NAME[settings.language] ?? SHEET_NAME.en} withArrow>
          <ActionIcon>
            <IconInfoCircle />
          </ActionIcon>
        </Tooltip> */}
      </div>
      <VariableSelectWrite
        style={{ marginTop: 0 }}
        placeholder="Enter extension id"
        dataState={dataState.extensionId}
        handleData={onChangeValue('extensionId')}
        handleSelect={onChangeValue('extensionId')}
      />
    </>
  );
}

export default TriggerExtension;