import {useEffect, useRef, useState} from "react";
import { IconFileUpload } from "@tabler/icons";
import { Input, Radio } from "@mantine/core";
import VariableSelectWrite from "../../../GlobalComponent/VariableSelectWrite";

function DeleteFile(props) {
    const fileRef = useRef(null)
    const [dataState, setDataState] = useState(props?.nodeData?.data?.options ?? {
        attachment: 'localFile',
        filePath: '',
        folderPath: '',
    })
    
    const onChangeValue = (key, target) => (e) => {
        if(e?.type === 'change'){
            setDataState((state) => ({
                ...state,
                [key] : target ? e.target[target] : e.target.value
            }));
        }
        else if(e?.type === "click"){
            const value = e.target.getAttribute("value");
            const newValue = dataState[key] + "${" + value + "}";
            setDataState((state) => ({ ...state, [key]: newValue }));
        }
        else{
            setDataState((state) => ({ ...state, [key]: e }));
        }
    }
    const onSelectFolder = async (e) => {
        e.preventDefault()
        const path = await window.electronAPI.getDictionary()
        setDataState(state => ({...state, folderPath: path }))
    }

    const handleUpload = (isFolder = false) => {
        setDataState({...dataState, filePath: fileRef.current.files[0].path});
    }

    useEffect(() => {
        props.handleSetDataBaseModal(dataState)
    }, [props, dataState])

    
    return (
        <>
            <Radio.Group
                value = {dataState.attachment}
                label = 'Choose seletor type'
                onChange={onChangeValue('attachment')}
                withAsterisk
                name="element"
            >
                <Radio value='localFile' label="Local file" />
                <Radio value='localFolder' label="Local folder" />
            </Radio.Group>
            {
                dataState.attachment === "localFile" &&
                <div className="custom-wrapper">
                    <div className="block-input">
                        <VariableSelectWrite
                            placeholder="Enter full path or upload file"
                            label="Path to the file"
                            dataState={dataState.filePath}
                            setDataState={onChangeValue('filePath')}
                            handleData={onChangeValue('filePath')}
                            handleSelect={onChangeValue('filePath')}
                        />
                    </div>
                    <Input
                        className="custom-file-input"
                        type="file"
                        ref={fileRef}
                        onChange={() => {
                            handleUpload(false)
                        }}
                        icon={<IconFileUpload size={16} />}
                        mt="0px"
                        styles={(theme) => ({
                            input: {
                                paddingRight: "0px",
                                '&::-webkit-file-upload-button': {
                                    visibility: 'hidden',
                                },
                                cursor: 'pointer',
                            },
                        })}
                    >
                    </Input>
                </div>
            }
            {
                dataState.attachment === "localFolder" &&
                <div className="custom-wrapper">
                    <div className="block-input">
                        <VariableSelectWrite
                            placeholder="Enter full path or upload file"
                            label="Path to the folder"
                            dataState={dataState.folderPath}
                            setDataState={onChangeValue('folderPath')}
                            handleData={onChangeValue('folderPath')}
                            handleSelect={onChangeValue('folderPath')}
                        />
                    </div>
                    <Input
                        className="custom-file-input"
                        type="file"
                        onClick={onSelectFolder}
                        icon={<IconFileUpload size={16} />}
                        mt="0px"
                        styles={{ input: {
                            paddingRight: "0px",
                            '&::-webkit-file-upload-button': {
                                visibility: 'hidden',
                            },
                            cursor: 'pointer',
                        }}}
                    />
                </div>
            }
        </>
    );
}

export default DeleteFile;
