import { useEffect, useState } from "react"
import VariableSelectWrite from "../../../GlobalComponent/VariableSelectWrite"
import { Radio } from "@mantine/core"
import VariableNumberInput from "../../../GlobalComponent/VariableNumberInput"

export const DEFAULT_SWITCH_FRAME_DATA = {
    elementSelect: "",
    frame: "subFrame",
    timeout: "30000",
}

function SwitchFrame(props) {
    const [dataState, setDataState] = useState(props?.nodeData?.data?.options ?? DEFAULT_SWITCH_FRAME_DATA)
    const onChangeValue = (key, target) => e => {
        if (e?.type === 'change') {
            setDataState((state) => ({...state, [key]: target ? e.target[target] : e.target.value}))
        } else if (e?.type === 'click') {
            const value = e.target.getAttribute('value')
            const newValue = dataState[key] + "${" + value + "}"
            setDataState((state) => ({...state, [key]: newValue}))
        } else {
            setDataState((state) => ({...state, [key]: e}))
        }
    }
	useEffect(() => {
        props.handleSetDataBaseModal(dataState)
    }, [props, dataState])
    return (
        <div>
            <Radio.Group
                value={dataState.frame}
                label="Frame"
                onChange={onChangeValue("frame")}
                name="element-exists"
            >
                <Radio value="subFrame" label="Sub frame" />
                <Radio value="mainFrame" label="Main frame" />
            </Radio.Group>
            {
                dataState.frame === "subFrame" ? (
                    <>
                        <VariableSelectWrite
                            dataState={dataState.elementSelect} label="Select element" placeholder="Please enter the element, such as #email input"
                            setDataState={onChangeValue("elementSelect")}
                            handleData={onChangeValue("elementSelect")}
                            handleSelect={onChangeValue("elementSelect")}
                        />
                    </>
                ) : null
            }
            <VariableNumberInput
                label="Timeout waiting (milliseconds)"
                dataState={dataState.timeout}
                handleData={onChangeValue("timeout")}
                isMillisecond={true}
            />
        </div>
    );
}

export default SwitchFrame;
